
<template>
    <modal :show="show" v-if=" isValid('CanAddTerminal') || isValid('CanEditTerminal') || isValid('Noble Admin')">

        <div class="modal-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">
                            <h6 class="modal-title m-0" id="myModalLabel">      {{ $t('AddBranchUsers.UpdateBranchUsers') }}</h6>
                            <button type="button" class="btn-close" v-on:click="close()"></button>
                        </div>
                        <div class="modal-header" v-else>
                            <h6 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddBranchUsers.AddBranchUsers') }}</h6>
                            <button type="button" class="btn-close" v-on:click="close()"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row ">

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('AddBranchUsers.User') }}:<span class="text-danger"> *</span></label>
                                    <usersDropdown v-model="terminal.userId" :alluser="true" />
                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('AddBranchUsers.Branch') }}:<span class="text-danger"> *</span></label>
                                    <branch-dropdown v-model="terminal.branchId" :values="terminal.branchId" :ismultiple="true" />
                                </div>


                            </div>
                        </div>

                        <div class="modal-footer " v-if="type=='Edit'">
                            <button type="button" class="btn btn-soft-primary btn-sm  " v-on:click="SaveBranch" v-bind:disabled="$v.terminal.$invalid"> {{ $t('AddBranches.Update') }}</button>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="close()">{{ $t('AddBranches.Cancel') }}</button>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit'">
                            <button type="button" class="btn btn-soft-primary btn-sm  " v-on:click="SaveBranch" v-bind:disabled="$v.terminal.$invalid"> {{ $t('AddBranches.Save') }}</button>
                            <button type="button" class="btn btn-soft-secondary btn-sm " v-on:click="close()">{{ $t('AddBranches.Cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Loading from 'vue-loading-overlay';

    import { required } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        components: {
            Loading,
        },
        props: ['show', 'terminal', 'type'],
        data: function () {
            return {
                loading: false,
            }
        },
        validations: {
            terminal: {
                userId: {
                    required
                },
                branchId: {
                    required
                },
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SaveBranch: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.loading = true;

                this.$https.post('/Branches/SaveBranchUser', this.terminal, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {
                        root.loading = false;
                        if (root.type != "Edit") {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: "Your Branch has been created!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                        else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update!' : 'تم التحديث!',
                                text: "Your Branch has been updated!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.loading = false;
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: "Your terminal Name  Already Exist!",
                            type: 'error',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                });
            },




        },
        mounted: function () {

        }
    }
</script>
