<template>
    <div class="row" v-if="isValid('CanViewBundleOffer')">
        <div class="col-lg-12 col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('Bundles.Bundles') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                    <li class="breadcrumb-item active">{{ $t('Bundles.Bundles') }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-if="isValid('CanAddBundleOffer')" v-bind:disabled="activePromotion>=3" v-on:click="AddBundles"
                                    href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Bundles.AddNew') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Bundles.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="input-group">
                        <button class="btn btn-secondary" type="button" id="button-addon1"><i class="fas fa-search"></i></button>
                        <input v-model="search" type="text" class="form-control" :placeholder="$t('Bundles.Search')" aria-label="Example text with button addon" aria-describedby="button-addon1">
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul class="nav nav-tabs" data-tabs="tabs">
                                <li class="nav-item">
                                    <a class="nav-link" v-bind:class="{ active: active == 'Active' }"
                                        v-on:click="makeActive('Active')" id="v-pills-home-tab" data-toggle="pill"
                                        href="#v-pills-home" role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">
                                        {{ $t('Bundles.Active') }}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" v-bind:class="{ active: active == 'History' }"
                                        v-on:click="makeActive('History')" id="v-pills-profile-tab" data-toggle="pill"
                                        href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                                        aria-selected="false">
                                        {{ $t('Bundles.History') }}
                                    </a>
                                </li>
                            </ul>

                            <div class="tab-content mt-3" id="nav-tabContent">
                                <div v-if="active == 'Active'">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.OFFER_NAME') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ProductName') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.BUY') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.GET') }}
                                                            </th>

                                                            <th>
                                                                {{ $t('Bundles.FromDate') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ToDate') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.Status') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(details, index) in bundlesList"
                                                            v-bind:key="details.id">
                                                            <td>
                                                                {{ index + 1 }}
                                                            </td>
                                                            <td v-if="isValid('CanEditBundleOffer')">
                                                                <strong>
                                                                    <a href="javascript:void(0)"
                                                                       v-on:click="EditBundles(details.id)">{{ details.offer }}</a>
                                                                </strong>
                                                            </td>
                                                            <td v-else>
                                                                {{ details.offer }}
                                                            </td>
                                                            <td>{{ details.productName }}</td>
                                                            <td>{{ details.buy }}</td>
                                                            <td>{{ details.get }}</td>

                                                            <td>{{ details.fromDate }}</td>
                                                            <td>{{ details.toDate }}</td>
                                                            <td>
                                                                <span v-if="details.isActive" class="badge badge-boxed  badge-outline-success">{{$t('color.Active')}}</span>
                                                                <span v-else class="badge badge-boxed  badge-outline-danger">{{$t('color.De-Active')}}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <span v-if="currentPage === 1 && rowCount === 0">
                                                {{ $t('Pagination.ShowingEntries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount < 10">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }} {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }} of {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                        </div>
                                        <div class=" col-lg-6">
                                            <div class="overflow-auto float-end" v-on:click="getPage()">
                                                <b-pagination pills size="sm" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="$t('Table.First')"
                                              :prev-text="$t('Table.Previous')"
                                              :next-text="$t('Table.Next')"
                                              :last-text="$t('Table.Last')" ></b-pagination>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div v-if="active == 'History'">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table mb-0" >
                                                    <thead class="thead-light table-hover">
                                                        <tr>
                                                            <th>
                                                               #
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.OFFER_NAME') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ProductName') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.BUY') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.GET') }}
                                                            </th>

                                                            <th>
                                                                {{ $t('Bundles.FromDate') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ToDate') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(details, index) in bundlesList"
                                                            v-bind:key="details.id">
                                                            <td>
                                                                {{ index + 1 }}
                                                            </td>
                                                            <td>

                                                                {{ details.offer }}

                                                            </td>
                                                            <td>{{ details.productName }}</td>

                                                            <td>{{ details.buy }}</td>
                                                            <td>{{ details.get }}</td>

                                                            <td>{{ details.fromDate }}</td>
                                                            <td>{{ details.toDate }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <span v-if="currentPage === 1 && rowCount === 0">
                                                {{ $t('Pagination.ShowingEntries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount < 10">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }} {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }} of {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                        </div>
                                        <div class=" col-lg-6">
                                            <div class="overflow-auto float-end" v-on:click="getPage()">
                                                <b-pagination pills size="sm" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="$t('Table.First')"
                                              :prev-text="$t('Table.Previous')"
                                              :next-text="$t('Table.Next')"
                                              :last-text="$t('Table.Last')" ></b-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!--<saleInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></saleInvoice>-->
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
export default {
    name: 'Bundles',
    mixins: [clickMixin],
    data: function () {
        return {
            show: false,
            bundlesList: [],
            active: 'Active',
            search: '',
            searchQuery: '',
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            activePromotion: 0,
        }
    },
    watch: {
        search: function (val) {
            this.getData(val, 1, this.active);
        }
    },
    methods: {
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        AddBundles: function () {
            this.$router.push('/AddBundles')
        },
        getPage: function () {
            this.getData(this.search, this.currentPage, this.active);
        },
        makeActive: function (item) {
            this.active = item;
            this.getData(this.search, 1, item);
        },
        getData: function (search, currentPage, status) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var branchId = localStorage.getItem('BranchId');


            this.$https.get('/Product/GetBundleCategoryItemsList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&branchId=' + branchId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {

                    if (response.data != null) {
                        root.bundlesList = response.data.results;
                        root.activePromotion = response.data.activeBundle;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                });
        },

        EditBundles: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Product/BundleCategoryItemsDetailsViaId?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.$router.push({
                        path: '/AddBundles',
                        query: { data: response.data }
                    })
                }
            });

        }
    },
    created: function () {

        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        this.makeActive("Active");
    }
}
</script>